let uni = {
	"$image":function(res){
		// return "http://119.23.40.216/SmartPlant-images/"+res
		return require("../../../assets/"+res)
	}
}

//以下内容，直接复制小程序的ParasUtil.js内容，两边保持一致

let self = {

	iotId:null,
	dialogNumber:null,
	dialogTime:null,
	dialogDate:null,
	dialogSelect:null,
	controlFunction:null,

	formatter:{
		controlMode(switchValue, value){
			return switchValue > 0 ? "自动" : "手动"
		},
		//生长箱水位状态 0x0有水，0x1缺水
		plantWaterLevel(switchValue, value){
			return value == 0 ? "有水" : "缺水"
		},
		//喷滴灌系统水位状态 0x0缺水，0x1有水
		waterLevel(switchValue, value){
			return value > 0 ? "有水" : "缺水"
		},
		powerSwitch(switchValue, value){
			return switchValue > 0 ? "已打开" : "已关闭"
		},
		plantName(switchValue, value){
			return ["无","叶菜","香料","蔬果","花卉"][value]
		},
		solenoidMode(switchValue, value){
			return ["手动","自动","时序"][value]
		},
		time(switchValue, value){
			let hour = parseInt(value/256)
			let minute = value%256
			return ("00"+hour).substr(-2) + ":" + ("00"+minute).substr(-2)
		},
		duration(switchValue, value){
			return (value/60).toFixed(1)
		},
		ec(switchValue, value){
			value = value/10000
			return value.toFixed(2)

			// if(value<20){
			// 	return value.toFixed(2)
			// }else if(value<200){
			// 	return value.toFixed(1)
			// }else{
			// 	return parseInt(value)
			// }
		},
		date(switchValue, value){
			let year = parseInt(value/65535)
			let yearH = parseInt(year/256)
			let yearL = parseInt(year%256)
			let month = parseInt(value%65536 / 256)
			let day = parseInt(value%256)
			return `${yearH*100+yearL}.${month}.${day}`
		},
		soilTemperature(switchValue, value){
			let symbol = (value & 0x8000)>0
			let signValue = symbol ? (value-65536) : value
			return (signValue/10).toFixed(1)
		},
		windOri(switchValue, value){
			return value/10 + "°"
		}

	},
	nutritionParas() {
		return [{
			name: '控制模式',
			icon: uni.$image('device/ic_mode.png'),
			switchIndex: 0x40,
			valueFormatter: self.formatter.controlMode
		},
			{
				name: '水泵开关',
				icon: uni.$image('device/ic_pumper.png'),
				selectedIcon: uni.$image('device/gif_pumper.gif'),
				switchIndex: 0x41,
				valueFormatter: self.formatter.powerSwitch
			},
			{
				name: '水泵开启时长',
				icon: uni.$image('device/ic_duration.png'),
				index: 0x42,
				unit: "分钟",
				writable: true
			},
			{
				name: '水泵关闭时长',
				icon: uni.$image('device/ic_duration.png'),
				index: 0x43,
				unit: "分钟",
				writable: true
			},
			{
				name: '温度',
				icon: uni.$image('device/ic_temperature.png'),
				index: 0x44,
				unit: "℃",
				rate:10
			},
			{
				name: 'PH',
				icon: uni.$image('device/ic_ph.png'),
				index: 0x45,
				unit: "",
				rate:100
			},
			{
				name: 'EC',
				icon: uni.$image('device/ic_ec.png'),
				index: 0x46,
				unit: "ms/cm",
				valueFormatter: self.formatter.ec
			},
			{
				name: '水位SQ',
				icon: uni.$image('device/ic_water_level.png'),
				index: 0x47,
				valueFormatter: self.formatter.plantWaterLevel
			}
		]
	},
	boxenvParas() {
		return [{
			name: '控制模式',
			icon: uni.$image('device/ic_mode.png'),
			switchIndex: 0xB0,
			valueFormatter: self.formatter.controlMode,
			fullWidth:true
		},
			// {
			// 		name: '',
			// 		icon: '',
			// 		index: 0,
			// 		valueFormatter: self.formatter.controlMode
			// },
			{
				name: '温度',
				icon: uni.$image('device/ic_temperature.png'),
				index: 0xB1,
				unit: "℃",
				rate:10
			},
			{
				name: '温度阈值',
				icon: uni.$image('device/ic_temperature.png'),
				index: 0xB2,
				unit: "℃",
				rate: 10,
				writable:true
			},
			{
				name: '湿度',
				icon: uni.$image('device/ic_humidity.png'),
				index: 0xB3,
				unit: "%",
				rate: 10,
			},
			{
				name: '湿度阈值',
				icon: uni.$image('device/ic_humidity.png'),
				index: 0xB4,
				unit: "%",
				writable:true,
				rate: 10,
			},
			{
				name: 'CO₂浓度',
				icon: uni.$image('device/ic_co2.png'),
				index: 0xB5,
				unit: "ppm"
			},
			{
				name: 'CO₂浓度阈值',
				icon: uni.$image('device/ic_co2.png'),
				index: 0xB6,
				unit: "ppm",
				writable:true
			},
			{
				name: '湿度阀门',
				icon: uni.$image('device/ic_solenoid_value.png'),
				selectedIcon: uni.$image('device/ic_solenoid_value_selected.png'),
				switchIndex: 0xB7,
				valueFormatter: self.formatter.powerSwitch
			},
			{
				name: 'CO₂阀门开关',
				icon: uni.$image('device/ic_solenoid_value.png'),
				selectedIcon: uni.$image('device/ic_solenoid_value_selected.png'),
				switchIndex: 0xB8,
				valueFormatter: self.formatter.powerSwitch
			},
			{
				name: '制冷水泵',
				icon: uni.$image('device/ic_solenoid_value.png'),
				selectedIcon: uni.$image('device/ic_solenoid_value_selected.png'),
				switchIndex: 0xBA,
				valueFormatter: self.formatter.powerSwitch
			},
			{
				name: '制冷压缩机',
				icon: uni.$image('device/ic_solenoid_value.png'),
				selectedIcon: uni.$image('device/ic_solenoid_value_selected.png'),
				switchIndex: 0xB9,
				valueFormatter: self.formatter.powerSwitch
			},
		]
	},

	lightParas(index){
		return [
			{
				name: '红光亮度',
				color: "#F44",
				icon: uni.$image('device/ic_radio_normal.png'),
				selectedIcon: uni.$image('device/ic_light_red.png'),
				writable:true,
				index: [0x11,0x21,0x31][index],
				switchIndex: [0x15,0x25,0x35][index]
			},
			{
				name: '白光亮度',
				color: "#FFF",
				icon: uni.$image('device/ic_radio_normal.png'),
				selectedIcon: uni.$image('device/ic_light_white.png'),
				writable:true,
				index: [0x12,0x22,0x32][index],
				switchIndex: [0x16,0x26,0x36][index]
			},
			{
				name: '蓝光亮度',
				color: "#44F",
				icon: uni.$image('device/ic_radio_normal.png'),
				selectedIcon: uni.$image('device/ic_light_blue.png'),
				writable:true,
				index: [0x13,0x23,0x33][index],
				switchIndex: [0x17,0x27,0x37][index]
			},
			{
				name: '紫光亮度',
				color: "#9370DB",
				icon: uni.$image('device/ic_radio_normal.png'),
				selectedIcon: uni.$image('device/ic_light_purple.png'),
				writable:true,
				index: [0x14,0x24,0x34][index],
				switchIndex: [0x18,0x28,0x38][index]
			},

		]
	},
	plantParas(index){
		return [
			{
				name: '控制模式',
				icon: uni.$image('device/ic_mode.png'),
				switchIndex: [0x10,0x20,0x30][index],
				valueFormatter: self.formatter.controlMode
			},
			{
				name: '菜品名称',
				icon: uni.$image('device/ic_plant_name.png'),
				index: [0x1B,0x2B,0x3B][index],
				valueFormatter: self.formatter.plantName,
				writable:true
			},
			{
				name: '风扇开关',
				icon: uni.$image('device/ic_fan.png'),
				selectedIcon: uni.$image('device/gif_fan.gif'),
				valueFormatter: self.formatter.powerSwitch,
				switchIndex:[0x1C,0x2C,0x3C][index]
			},
			{
				name: '开灯时间',
				icon: uni.$image('device/ic_duration.png'),
				index: [0x1A,0x2A,0x3A][index],
				valueFormatter: self.formatter.time,
				writable:true
			},
			{
				name: '开灯时长',
				icon: uni.$image('device/ic_duration.png'),
				index: [0x19,0x29,0x39][index],
				rate: 60,
				unit: "小时",
				writable:true
			},
			{
				name: '种植时间',
				icon: uni.$image('device/ic_plant_time.png'),
				index: [0x1D,0x2D,0x3D][index],
				writable:true,
				valueFormatter: self.formatter.date
			},


		]
	},
	irriEnvParas(){
		return [
			{
				name: '湿度',
				icon: uni.$image('device/ic_humidity.png'),
				index: 0xA3,
				unit: "%",
				rate: 10
			},
			{
				name: '控制模式',
				icon: uni.$image('device/ic_mode.png'),
				switchIndex: 0xA0,
				valueFormatter: self.formatter.controlMode,
			},
			{
				name: '温度',
				icon: uni.$image('device/ic_temperature.png'),
				index: 0xA1,
				unit: "℃",
				rate: 10
			},
			{
				name: '排气扇',
				icon: uni.$image('device/ic_fan.png'),
				selectedIcon: uni.$image('device/gif_fan.gif'),
				valueFormatter: self.formatter.powerSwitch,
				switchIndex:0xAA
			},
			{
				name: '大气压',
				icon: uni.$image('device/ic_air_pressure.png'),
				index: 0xA8,
				unit:"KPa",
				rate: 1000
			},
			{
				name: '加湿器阀门',
				icon: uni.$image('device/ic_fan.png'),
				selectedIcon: uni.$image('device/gif_fan.gif'),
				valueFormatter: self.formatter.powerSwitch,
				switchIndex:0xAB
			},
			{
				name: '风速',
				icon: uni.$image('device/ic_wind_speed.png'),
				index: 0xA5,
				unit: "m/s",
				rate: 100
			},
			{
				name: '空调开关',
				icon: uni.$image('device/ic_fan.png'),
				selectedIcon: uni.$image('device/gif_fan.gif'),
				valueFormatter: self.formatter.powerSwitch,
				switchIndex:0xA9
			},
			{
				name: '风向',
				icon: uni.$image('device/ic_wind_ori.png'),
				index: 0xA7,
				unit:"",
				valueFormatter: self.formatter.windOri,
			},
			{
				name: '温度阈值',
				icon: uni.$image('device/ic_temperature.png'),
				index: 0xA2,
				unit: "℃",
				writable:true,
				rate: 10
			},
			{
				name: 'CO₂浓度',
				icon: uni.$image('device/ic_co2.png'),
				unit: "ppm",
				index: 0xA6,
			},
			{
				name: '湿度阈值',
				icon: uni.$image('device/ic_humidity.png'),
				index: 0xA4,
				unit: "%",
				writable:true,
				rate: 10
			},
		]
	},
	irriNutriParas(){
		return [
			{
				name: 'PH',
				icon: uni.$image('device/ic_ph.png'),
				index: 0x51,
				unit: "",
				rate: 100
			},
			{
				name: 'EC',
				icon: uni.$image('device/ic_ec.png'),
				index: 0x52,
				unit: "ms/cm",
				valueFormatter: self.formatter.ec
			},
			{
				name: '温度',
				icon: uni.$image('device/ic_temperature.png'),
				index: 0x53,
				unit: "℃",
				rate: 10,
			},
			{
				name: '水位SQ',
				icon: uni.$image('device/ic_water_level.png'),
				index: 0x54,
				valueFormatter: self.formatter.waterLevel
			},
			{
				name: '水泵',
				icon: uni.$image('device/ic_pumper.png'),
				selectedIcon: uni.$image('device/gif_pumper.gif'),
				switchIndex: 0x55,
				valueFormatter: self.formatter.powerSwitch
			},
		]
	},
	irriSoilParas(){
		return [
			{
				name: '温度',
				icon: uni.$image('device/ic_temperature.png'),
				index: 0x61,
				unit: "℃",
				valueFormatter: self.formatter.soilTemperature
			},
			{
				name: 'EC',
				icon: uni.$image('device/ic_ec.png'),
				index: 0x63,
				unit: "ms/cm",
				valueFormatter: self.formatter.ec
			},
			{
				name: '湿度',
				icon: uni.$image('device/ic_humidity.png'),
				index: 0x62,
				unit: "%",
				rate: 10
			},
			{
				name: '湿度阈值',
				icon: uni.$image('device/ic_humidity.png'),
				index: 0x64,
				unit: "%",
				writable:true,
				rate: 10
			},
		]
	},
	irriSolenoidParas(index){
		return [
			{
				name: '工作模式',
				icon: uni.$image('device/ic_mode.png'),
				index: [0x71,0x7A,0x83][index],
				writable:true,
				valueFormatter: self.formatter.solenoidMode
			},
			{
				name: '电磁阀开关',
				icon: uni.$image('device/ic_solenoid_value.png'),
				selectedIcon: uni.$image('device/ic_solenoid_value_selected.png'),
				switchIndex: [0x72,0x7B,0x84][index],
				valueFormatter: self.formatter.powerSwitch
			},

		]
	},

	showDialog(item){

		switch(item.index){
			//时间
			case 0x1A:
			case 0x2A:
			case 0x3A:
				self.dialogTime.open({
					title:item.name,
					value:item.value,
					onConfirm(value){
						self.controlFunction({[item.index]:parseInt(value)})
					}
				})
				break

			//菜品名称
			case 0x1B:
			case 0x2B:
			case 0x3B:
				self.dialogSelect.open({
					title:item.name,
					value:item.value,
					items:["无","叶菜","香料","蔬果","花卉"],
					onConfirm(value){
						self.controlFunction({[item.index]:parseInt(value)})
					}
				})
				break
			//日期
			case 0x1D:
			case 0x2D:
			case 0x3D:
				self.dialogDate.open({
					title:item.name,
					value:item.value,
					onConfirm(value){
						self.controlFunction({[item.index]:parseInt(value)})
					}
				})
				break
			//电磁阀控制模式
			case 0x0171:
			case 0x017A:
			case 0x0183:
			case 0x0271:
			case 0x027A:
			case 0x0283:
			case 0x0371:
			case 0x037A:
			case 0x0383:
			case 0x0471:
			case 0x047A:
			case 0x0483:
			case 0x0571:
			case 0x057A:
			case 0x0583:
				self.dialogSelect.open({
					title:item.name,
					value:item.value,
					items:["手动","自动","时序"],
					onConfirm(value){
						self.controlFunction({[item.index]:parseInt(value)})
					}
				})
				break

			default:
				var title = item.name
				if(item.unit){ title+= `(${item.unit})`}
				self.dialogNumber.open({
					title:title,
					placeholder:item.placeholder || "",
					onConfirm(value){
						if(item.rate){
							value = parseInt(item.rate * value)
						}
						self.controlFunction({[item.index]:parseInt(value)})
					}
				})
				break
		}


	},

}
export default self

